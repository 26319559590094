exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-meglife-js": () => import("./../../../src/pages/about_meglife.js" /* webpackChunkName: "component---src-pages-about-meglife-js" */),
  "component---src-pages-awesome-slider-js": () => import("./../../../src/pages/awesome_slider.js" /* webpackChunkName: "component---src-pages-awesome-slider-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog_article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-community-development-js": () => import("./../../../src/pages/community_development.js" /* webpackChunkName: "component---src-pages-community-development-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact_form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-exposure-visit-js": () => import("./../../../src/pages/exposure_visit.js" /* webpackChunkName: "component---src-pages-exposure-visit-js" */),
  "component---src-pages-filter-gallery-js": () => import("./../../../src/pages/filter_gallery.js" /* webpackChunkName: "component---src-pages-filter-gallery-js" */),
  "component---src-pages-financial-pattern-js": () => import("./../../../src/pages/financial_pattern.js" /* webpackChunkName: "component---src-pages-financial-pattern-js" */),
  "component---src-pages-gis-js": () => import("./../../../src/pages/gis.js" /* webpackChunkName: "component---src-pages-gis-js" */),
  "component---src-pages-grievance-js": () => import("./../../../src/pages/grievance.js" /* webpackChunkName: "component---src-pages-grievance-js" */),
  "component---src-pages-highlight-article-js": () => import("./../../../src/pages/highlight_article.js" /* webpackChunkName: "component---src-pages-highlight-article-js" */),
  "component---src-pages-highlight-newsletter-js": () => import("./../../../src/pages/highlight_newsletter.js" /* webpackChunkName: "component---src-pages-highlight-newsletter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institutional-js": () => import("./../../../src/pages/institutional.js" /* webpackChunkName: "component---src-pages-institutional-js" */),
  "component---src-pages-latest-notification-js": () => import("./../../../src/pages/latest_notification.js" /* webpackChunkName: "component---src-pages-latest-notification-js" */),
  "component---src-pages-latest-tender-js": () => import("./../../../src/pages/latest_tender.js" /* webpackChunkName: "component---src-pages-latest-tender-js" */),
  "component---src-pages-news-letters-js": () => import("./../../../src/pages/news_letters.js" /* webpackChunkName: "component---src-pages-news-letters-js" */),
  "component---src-pages-notice-home-notification-js": () => import("./../../../src/pages/notice/home_notification.js" /* webpackChunkName: "component---src-pages-notice-home-notification-js" */),
  "component---src-pages-notice-notification-js": () => import("./../../../src/pages/notice/notification.js" /* webpackChunkName: "component---src-pages-notice-notification-js" */),
  "component---src-pages-notice-tender-js": () => import("./../../../src/pages/notice/tender.js" /* webpackChunkName: "component---src-pages-notice-tender-js" */),
  "component---src-pages-notice-ticker-notification-js": () => import("./../../../src/pages/notice/ticker_notification.js" /* webpackChunkName: "component---src-pages-notice-ticker-notification-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo_gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-pop-up-js": () => import("./../../../src/pages/pop_up.js" /* webpackChunkName: "component---src-pages-pop-up-js" */),
  "component---src-pages-project-component-community-development-js": () => import("./../../../src/pages/project_component/community_development.js" /* webpackChunkName: "component---src-pages-project-component-community-development-js" */),
  "component---src-pages-project-component-institutional-js": () => import("./../../../src/pages/project_component/institutional.js" /* webpackChunkName: "component---src-pages-project-component-institutional-js" */),
  "component---src-pages-project-component-sustainable-js": () => import("./../../../src/pages/project_component/sustainable.js" /* webpackChunkName: "component---src-pages-project-component-sustainable-js" */),
  "component---src-pages-project-impact-js": () => import("./../../../src/pages/project_impact.js" /* webpackChunkName: "component---src-pages-project-impact-js" */),
  "component---src-pages-project-management-bodies-js": () => import("./../../../src/pages/project_management_bodies.js" /* webpackChunkName: "component---src-pages-project-management-bodies-js" */),
  "component---src-pages-project-management-unit-js": () => import("./../../../src/pages/project_management_unit.js" /* webpackChunkName: "component---src-pages-project-management-unit-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-quarterly-update-js": () => import("./../../../src/pages/quarterly_update.js" /* webpackChunkName: "component---src-pages-quarterly-update-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-rti-js": () => import("./../../../src/pages/rti.js" /* webpackChunkName: "component---src-pages-rti-js" */),
  "component---src-pages-screen-reader-js": () => import("./../../../src/pages/screen_reader.js" /* webpackChunkName: "component---src-pages-screen-reader-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-social-media-facebook-js": () => import("./../../../src/pages/social_media/facebook.js" /* webpackChunkName: "component---src-pages-social-media-facebook-js" */),
  "component---src-pages-social-media-instagram-js": () => import("./../../../src/pages/social_media/instagram.js" /* webpackChunkName: "component---src-pages-social-media-instagram-js" */),
  "component---src-pages-social-media-reels-js": () => import("./../../../src/pages/social_media/reels.js" /* webpackChunkName: "component---src-pages-social-media-reels-js" */),
  "component---src-pages-social-media-social-tab-js": () => import("./../../../src/pages/social_media/social_tab.js" /* webpackChunkName: "component---src-pages-social-media-social-tab-js" */),
  "component---src-pages-social-media-twitter-js": () => import("./../../../src/pages/social_media/twitter.js" /* webpackChunkName: "component---src-pages-social-media-twitter-js" */),
  "component---src-pages-social-media-youtube-js": () => import("./../../../src/pages/social_media/youtube.js" /* webpackChunkName: "component---src-pages-social-media-youtube-js" */),
  "component---src-pages-sustainable-js": () => import("./../../../src/pages/sustainable.js" /* webpackChunkName: "component---src-pages-sustainable-js" */),
  "component---src-pages-taglist-js": () => import("./../../../src/pages/taglist.js" /* webpackChunkName: "component---src-pages-taglist-js" */),
  "component---src-pages-team-member-js": () => import("./../../../src/pages/team_member.js" /* webpackChunkName: "component---src-pages-team-member-js" */),
  "component---src-pages-tender-js": () => import("./../../../src/pages/tender.js" /* webpackChunkName: "component---src-pages-tender-js" */),
  "component---src-pages-update-of-the-week-js": () => import("./../../../src/pages/update_of_the_week.js" /* webpackChunkName: "component---src-pages-update-of-the-week-js" */),
  "component---src-pages-village-info-js": () => import("./../../../src/pages/village_info.js" /* webpackChunkName: "component---src-pages-village-info-js" */),
  "component---src-pages-village-under-meglife-js": () => import("./../../../src/pages/village_under_meglife.js" /* webpackChunkName: "component---src-pages-village-under-meglife-js" */),
  "component---src-pages-who-is-who-executive-committee-js": () => import("./../../../src/pages/who_is_who/executive_committee.js" /* webpackChunkName: "component---src-pages-who-is-who-executive-committee-js" */),
  "component---src-pages-who-is-who-governance-council-js": () => import("./../../../src/pages/who_is_who/governance_council.js" /* webpackChunkName: "component---src-pages-who-is-who-governance-council-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog_article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-exposure-visit-js": () => import("./../../../src/templates/exposure_visit.js" /* webpackChunkName: "component---src-templates-exposure-visit-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-notice-js": () => import("./../../../src/templates/notice.js" /* webpackChunkName: "component---src-templates-notice-js" */),
  "component---src-templates-project-component-js": () => import("./../../../src/templates/project_component.js" /* webpackChunkName: "component---src-templates-project-component-js" */)
}

